import Vue from 'vue'
import App from './App.vue'
import router from './router'
import money from 'v-money'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueResource from 'vue-resource';
import VueI18n from 'vue-i18n'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueI18n)
// telling vue.js to use this package
Vue.use(VueResource);

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style.css'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(money, { precision: 2 })
Vue.use(VueCryptojs)
Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    return value
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })
  return formatter.format(value);
})


Vue.http.interceptors.push(function (request, next) {
  request.timeout = 120000
  request.headers.set('Authorization', 'Bearer ' + window.sessionStorage.getItem('token'));
  next(
    function (response) {

      if (response.status === 403) {

        router.push('/login');
      }

    })
})
const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency', currency: 'USD'
    }
  },
  'pt-BR': {
    currency: {
      style: 'currency', currency: 'BRL'
    }
  }

}
const i18n = new VueI18n({
  locale: 'pt_BR',
  numberFormats
})
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
