<template>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand href="#" v-b-toggle.menu-principal><b-icon-list scale="2" /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item href="#/">
              Home
            </b-nav-item>
            <b-nav-item href="#/cadastro-movimentacao">
              Cadastro de movimentação
            </b-nav-item>

            

          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">

            <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            {{login}}
          </template>
          <b-dropdown-item v-on:click="doLogin(item)" v-for="(item,index) of logins" :key="index" href="#">{{item}}</b-dropdown-item>

          <b-dropdown-item  href="#" v-on:click="sair()">Sair</b-dropdown-item>
        </b-nav-item-dropdown>


          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
</template>
<script>
import login from '@/resources/loginResource'
import commons from '@/commons/index.js'
export default {
  mixins:[commons],
    data() {
        return {
          login:'',
          logins:[]
        }
    },
    beforeMount() {
      this.init();
    },
    mounted() {
    },
    methods: {
      doLogin(user) {
        var hash = window.localStorage.getItem("hash");
        var frase = window.sessionStorage.getItem("frase");

        frase = this.$CryptoJS.AES.decrypt(frase, "q1w2e3").toString(this.$CryptoJS.enc.Utf8)
        hash = this.$CryptoJS.AES.decrypt(hash, frase).toString(this.$CryptoJS.enc.Utf8)
        if(hash =='') {
          window.alert("Frase Inválida");
        } else {
          hash = JSON.parse(hash);
          window.sessionStorage.setItem("toLogin",JSON.stringify(hash));
          var form={};
          form.loginHash=hash[user];
          login.loginByHash(form).then(result => {
            if(result.status == 200) {
              window.sessionStorage.setItem("token" ,result.body.token);
              window.sessionStorage.setItem("login" ,result.body.login);
              window.location.reload();
            }
          }, (erro) => {
            console.log(erro)

          })
        }
      },
      init() {
        this.login = window.sessionStorage.getItem("login");
        this.getLogins();


      },
      getLogins() {
        
        var aux = window.localStorage.getItem("logins");
        if(aux !== undefined) {
          this.logins.splice(0,this.logins.length);
          aux = JSON.parse(aux);
          if(aux instanceof Array) {
            for(var s of aux) {
              if(s== this.login){
                continue;
              }
              this.logins.push(s);
            }
          }
        }
      },
      
      sair() {
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("login");
        window.sessionStorage.removeItem("toLogin");
        window.location.href="#/login"
      }
    }
}
</script>