import Vue from 'vue'
import Resource from 'vue-resource'
Vue.use(Resource)

var customActions = {
    
    login: { method: 'POST', url: '/auth/login' },
    loginByHash: { method: 'POST', url: '/auth/loginByHash' },
    register: { method: 'POST', url: '/auth/register' },

    
}

export default Vue.resource('/', {}, customActions) 