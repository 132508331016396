

export default {
	methods: {
		moeda(valor) {
			return $n(valor, 'currency', 'pt-BR')
		},
		loadingMsg(msg) {
			this.$store.state.app.loadingMsg = msg
		},
		loading(active, timeOut) {
			this.$store.state.app.loadingActive = active
			if (!active) {
				this.$store.state.app.loadingMsg = ''
			}
			var to = 10000;
			if (timeOut != undefined) {
				to = timeOut
			}
			if (this.$store.state.app.loadingActive) {
				setTimeout(() => {
					this.loading(false)
				}, to);
			}
		}
	},
}